const feedbackForm = document.forms.feedbackForm;

feedbackForm?.addEventListener('submit', onFeedbackFormSubmit);

function onFeedbackFormSubmit(e) {
  e.preventDefault();

  const data = {
    name: feedbackForm?.elements?.name?.value,
    tel: feedbackForm?.elements?.tel?.value,
    msg: feedbackForm?.elements?.msg?.value,
    type: "feedback",
  }

  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));

  sendOrder(formData);
  e.target.reset();
}

function sendOrder(formData) {
  let sendURL = '/order.php';
  try {
    let response = fetch(sendURL, {
      method: "POST",
      body: formData,
    });
    window.location.href = sendURL;
  } catch (error) {
    console.log("error")
  }
}

