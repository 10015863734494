import { showElement, hideElement } from '/src/common/js';

const bodyRef = document.querySelector('body');
const burgerMenuRef = document.querySelector('[data-burger-menu]');
const burgerMenuBtnRef = document.querySelector('[data-burger-menu-btn]');
const burgerMenuBackdropRef = document.querySelector('[data-burger-menu-backdrop]');

updateSize();

window.addEventListener('resize', updateSize);
burgerMenuBtnRef?.addEventListener('click', toggleBurgerMenu);
burgerMenuRef?.addEventListener('click', onBurgerMenuClick);

function onBurgerMenuClick(e) {
  if (!e.target.classList.contains("nav__link")) return;
  hideBurgerMenu();
}

function toggleBurgerMenu() {
  const expanded = burgerMenuBtnRef.getAttribute('aria-expanded') === 'true' || false;

  if (expanded) {
    hideElement(burgerMenuRef);
    hideElement(burgerMenuBackdropRef);
  }
  else {
    showElement(burgerMenuRef);
    showElement(burgerMenuBackdropRef);
  }

  bodyRef.classList.toggle('burgerMenuIsOpen');
  burgerMenuBtnRef.setAttribute('aria-expanded', !expanded);
}

function hideBurgerMenu() {
  hideElement(burgerMenuRef);
  hideElement(burgerMenuBackdropRef);
  bodyRef.classList.remove('burgerMenuIsOpen');
  burgerMenuBtnRef.setAttribute('aria-expanded', false);
}

function updateSize() {
  const innerWidth = window.innerWidth;

  if (innerWidth <= 1440) hideBurgerMenu();
  // else showBurgerMenu();
}
