import { hideElement, showElement } from '/src/common/js';
// import sendMessage from '/src/services/telegram';
// === modal ===
const orderModal = document.querySelector('[data-modal="order"]');
const orderModalBackdrop = orderModal?.querySelector('[data-modal-backdrop]');
const orderModalBtnClose = orderModal?.querySelector('[data-modal-btn-close]');

const orderImgRef = orderModal?.querySelector('[data-order-img]');
const orderTitleRef = orderModal?.querySelector('[data-order-title]');
const orderWeightRef = orderModal?.querySelector('[data-order-weight]');
// === counter ===
const counterRef = orderModal.querySelector('[data-counter]');
const counterValueRef = orderModal.querySelector('[data-counter-value]');
const orderTotalRef = orderModal.querySelector('[data-order-total]');
const orderSuccessRef = orderModal.querySelector('[data-order-success]');

// === form ===
const orderForm = document.forms.orderForm;

document.addEventListener('click', checkBtnAndOpenModal);
counterRef?.addEventListener('click', onCounterBtnClick);
orderForm?.addEventListener('submit', onOrderFormSubmit);

function checkBtnAndOpenModal(e) {
  const isOrderBtn = e.target.hasAttribute('data-order-btn');
  if (!isOrderBtn) return;
  setOrderData(e);
  openModal();
}
export function openModal() {
  showElement(orderModal);
  document.body.classList.add('modalIsOpen');

  orderModalBackdrop?.addEventListener('click', onBackdropClick);
  orderModalBtnClose?.addEventListener('click', closeModal);
  window.addEventListener('keydown', onEscKeyPress);
}
export function closeModal() {
  hideElement(orderModal);
  hideElement(orderSuccessRef);
  document.body.classList.remove('modalIsOpen');

  orderModalBackdrop?.removeEventListener('click', onBackdropClick);
  orderModalBtnClose?.removeEventListener('click', closeModal);
  window.removeEventListener('keydown', onEscKeyPress);
}

function onBackdropClick(e) {
  if (e.currentTarget === e.target) closeModal();
}
function onEscKeyPress(e) {
  if (orderModalBackdrop?.classList.contains('isHidden')) return;
  if (e.code === 'Escape') closeModal();
}

function setOrderData(e) {
  const orderBtn = e.target;
  const orderData = {
    img: orderBtn?.dataset?.orderImg,
    title: orderBtn?.dataset?.orderTitle,
    weight: orderBtn?.dataset?.orderWeight,
    price: orderBtn?.dataset?.orderPrice,
  };
  // === img ===
  orderImgRef?.setAttribute('src', `${orderData?.img}`);
  orderImgRef?.setAttribute('alt', `${orderData?.title}`);
  orderImgRef?.setAttribute('data-order-img', `${orderData?.img}`);
  // === title ===
  orderTitleRef?.setAttribute('data-order-title', `${orderData?.title}`);
  orderTitleRef.innerHTML = `${orderData?.title}`;
  // === weight ===
  orderWeightRef?.setAttribute('data-order-weight', `${orderData?.weight}`);
  orderWeightRef.innerHTML = `${orderData?.weight}`;
  // === price ===
  orderTotalRef?.setAttribute('data-order-price', `${orderData?.price}`);
  orderTotalRef?.setAttribute('data-order-total', `${orderData?.price}`);
  orderTotalRef.innerHTML = `${orderData?.price}`;

  // === counter reset ===
  counterValueRef?.setAttribute('data-counter-value', 1);
  counterValueRef?.setAttribute('value', 1);
  counterValueRef.value = 1;
}

function onCounterBtnClick(e) {
  const counterRef = e.target?.dataset?.counter;
  if (!(counterRef === 'decrement' || counterRef === 'increment')) return;

  const orderTotalRef = document.querySelector('[data-order-total]');
  const counterValueRef = orderForm?.elements?.counter;

  const orderPrice = Number(orderTotalRef?.dataset?.orderPrice);
  const counterValue = Number(counterValueRef?.value);
  let newCounterValue = counterValue;

  switch (counterRef) {
    case 'increment':
      newCounterValue = counterValue + 1;
      break;
    case 'decrement':
      newCounterValue = newCounterValue === 1 ? 1 : counterValue - 1;
      break;

    default:
      break;
  }

  const newOrderTotal = orderPrice * newCounterValue;
  counterValueRef.value = newCounterValue;
  counterValueRef.setAttribute('value', newCounterValue);
  counterValueRef.setAttribute('data-counter-value', newCounterValue);
  orderTotalRef.setAttribute('data-order-total', newOrderTotal);
  orderTotalRef.innerHTML = newOrderTotal;
}

function onOrderFormSubmit(e) {
  e.preventDefault();

  const data = {
    // img: `${orderImgRef?.dataset.orderImg}`,
    title: orderTitleRef?.dataset.orderTitle,
    weight: orderWeightRef?.dataset.orderWeight,
    count: orderForm?.elements?.counter?.value,
    price: `${orderTotalRef?.dataset?.orderPrice}`,
    total: `${orderTotalRef?.dataset?.orderTotal}`,
    name: orderForm?.elements?.name?.value,
    tel: orderForm?.elements?.tel?.value,
    type: "order",
  };

  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));

  sendOrder(formData);
  e.target.reset();
 

  // sendOrder(e.target);
  // sendMessage(setDataToRequest(data))
  // showElement(orderSuccessRef);
}

function sendOrder(formData) {
  let sendURL = '/order.php';
  try {
    let response = fetch(sendURL, {
      method: "POST",
      body: formData,
    });
    window.location.href = sendURL;
    // if (response.ok) { }
  } catch (error) {
    console.log("error")
  }
}

// function setDataToRequest(data) {
//   const {img, title, weight, count, price, total, name, tel } = data;

//   let message = "";
//   message += `--------------------------------------------------\n`;
//   message += `Зображення: ${img}\n`;
//   message += `Назва: ${title}\n`;
//   message += `Вага (од.): ${weight}\n`;
//   message += `Кількість: ${count}\n`;
//   message += `Ціна (за од.): ${price} грн\n`;
//   message += `Загальна вартість: ${total} грн\n`;
//   message += `І'мя: ${name}\n`;
//   message += `Телефон: ${tel}\n`;
//   message += `--------------------------------------------------\n`;

//   return message;
// }

